import { AxiosResponse } from 'axios'

import { AddAuthSession, _axios } from './axios'

const SIGNIN = `/signin`
const CREATE_USER = `/users`

export const authApi = () => {
  const signIn = async (
    email: string,
    pass: string,
  ): Promise<AxiosResponse<any>> => {
    const params = {
      email: email,
      password: pass,
    }
    return await _axios()
      .post(SIGNIN, params)
      .then((res) => {
        AddAuthSession(res.data.accessToken)
        return res.data.id
      })
  }

  const createUser = async (data: {
    loginId: string
    name: string
    email: string
    branchId: string
  }): Promise<AxiosResponse<any>> => {
    return await _axios()
      .post(CREATE_USER, data)
      .then((res) => {
        res.data = res.data.user
        return res
      })
  }

  return {
    signIn,
    createUser,
  }
}
