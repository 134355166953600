import { Company } from './company'
import { Option } from './option'
import { User } from './user'

export interface CompanyBranch {
  id?: string
  companyId?: string
  branchName: string
  address: string
  phoneNumber?: string
  faxNumber?: string
  bankNumber?: string
  bankBranchName?: string
  bankBranchNumber?: string
  bankName?: string
  bankCode?: string
  plugins?: number[]
  company?: Company
  users?: User[]
}

export const PLUGIN = {
  EXPORT_FOR_OBIC: 1 << 0,
  REWVIEW: 1 << 1,
}

export const PLUGIN_OPTIONS: Option[] = [
  {
    label: 'オービック給料連携用csv出力',
    value: PLUGIN.EXPORT_FOR_OBIC,
  },
  {
    label: 'アンケート',
    value: PLUGIN.REWVIEW,
  },
]
