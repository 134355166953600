import { Divider } from '@mui/material'
import Container from '@mui/material/Container'
import dayjs from 'dayjs'

import KanseiBreadcrumbs from 'components/breadcrumb'
import KanseiButton from 'components/button'
import { workFlowApi } from 'hooks/api/workflow'

export default function WorkFlowPage() {
  const links = [
    {
      title: 'ワークフロー',
    },
  ]

  const getLoginStatus = () => {
    if (confirm('実行しますか')) {
      workFlowApi()
        .loginStatus()
        .then((res) => {
          const reader = new FileReader()
          reader.onload = (e: any) => {
            const tmpLink = document.createElement('a')
            const now = dayjs().format('YYYY-MM-DD-HH-mm')
            tmpLink.download = `ログイン状況-${now}.csv`
            if (typeof e.target.result !== 'string') return
            tmpLink.href = e.target.result
            tmpLink.click()
          }

          const bom = new Uint8Array([0xef, 0xbb, 0xbf])
          const csvContent = new Blob([bom, res.data], {
            type: 'text/csv',
          })
          reader.readAsDataURL(csvContent)
        })
    }
  }

  return (
    <Container>
      <KanseiBreadcrumbs links={links} style={{ marginBottom: '15px' }} />
      <Divider>login状況</Divider>
      <KanseiButton onClick={getLoginStatus}>実行</KanseiButton>
    </Container>
  )
}
