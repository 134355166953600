import { Breadcrumbs, Typography } from '@mui/material'
import { CSSProperties } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  links: {
    title: string
    to?: string
  }[]
  style?: CSSProperties
}

export default function KanseiBreadcrumbs(props: Props) {
  const { links, style } = props

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={style}>
      {links &&
        links.map((link) => {
          if (!!link.to) {
            return (
              <Link key={link.title} color="inherit" to={link.to}>
                {link.title}
              </Link>
            )
          } else {
            return (
              <Typography key={link.title} color="text.primary">
                {link.title}
              </Typography>
            )
          }
        })}
    </Breadcrumbs>
  )
}
