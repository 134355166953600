import { Edit } from '@mui/icons-material'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import KanseiBreadcrumbs from 'components/breadcrumb'
import KanseiButton from 'components/button'
import KanseiDialog from 'components/dialog'
import KanseiMultiSelectBox from 'components/multiSelect'
import KanseiTextField from 'components/textField'
import { authApi } from 'hooks/api/auth'
import { companyApi } from 'hooks/api/company'
import { CompanyBranch, PLUGIN_OPTIONS } from 'types/companyBranch'
import { User } from 'types/user'

export interface BranchForm {
  branchName: string
  address: string
  phoneNumber: string
  faxNumber: string
  plugins: number[]
}

export interface UserForm {
  id?: string
  loginId: string
  name: string
  email: string
}

export default function CompanyBranchPage() {
  const [companyBranch, setCompanyBranch] = useState<CompanyBranch>()
  const [open, setOpen] = useState<boolean>(false)
  const { id, branchId } = useParams<{ id: string; branchId: string }>()
  const {
    control: userControl,
    handleSubmit: userHandleSubmit,
    formState: { errors },
    reset: userReset,
  } = useForm<UserForm>()

  const {
    control: branchControl,
    handleSubmit: branchHandleSubmit,
    reset: branchReset,
  } = useForm<BranchForm>()

  useEffect(() => {
    companyApi()
      .findBranchById(branchId!)
      .then((res) => {
        setCompanyBranch(res.data)
        branchReset(res.data)
      })
  }, [])

  const links = [
    {
      title: '警備会社一覧',
      to: '/company',
    },
    {
      title:
        !!companyBranch && !!companyBranch.company
          ? companyBranch.company?.name
          : '',
      to: `/company/${id}`,
    },
    {
      title: !!companyBranch ? companyBranch.branchName : '',
    },
  ]

  const showEditModal = (user: User) => {
    const data: UserForm = {
      id: user.id,
      name: user.name,
      loginId: user.loginId,
      email: user.email,
    }
    userReset(data)
    setOpen(true)
  }

  const showCreateModal = () => {
    userReset({})
    setOpen(true)
  }

  const handleCloseDialog = (): void => {
    userReset({})
    setOpen(false)
  }

  const onSubmit = (form: UserForm) => {
    if (!!form.id) return alert('更新はまだ対応できてないです')
    const data = {
      loginId: form.loginId.trim(),
      name: form.name.trim(),
      email: form.email.trim(),
      branchId: branchId!,
    }
    if (confirm('作成しますか？')) {
      authApi()
        .createUser(data)
        .then((res) => {
          const reader = new FileReader()
          reader.onload = (e: any) => {
            const tmpLink = document.createElement('a')
            const title = `${companyBranch?.company?.name}-${companyBranch?.branchName}-${data.name}.csv`
            tmpLink.download = title
            if (typeof e.target.result !== 'string') return
            tmpLink.href = e.target.result
            tmpLink.click()
          }

          const bom = new Uint8Array([0xef, 0xbb, 0xbf])
          const csvContent = new Blob([bom, res.data], {
            type: 'text/csv',
          })
          reader.readAsDataURL(csvContent)
          handleCloseDialog()
          console.log('作成し、csvをダウンロードしました')
        })
    } else {
      console.log('キャンセルしました')
    }
  }
  const onBranchSubmit = (form: BranchForm) => {
    const data: CompanyBranch = {
      id: branchId!,
      ...form,
    }
    companyApi()
      .editBranch(branchId!, data)
      .then(() => {
        window.location.reload()
      })
  }

  return (
    <Box>
      <KanseiBreadcrumbs links={links} style={{ marginBottom: '15px' }} />
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={branchHandleSubmit(onBranchSubmit)}
      >
        <Grid item xs={2}>
          <KanseiTextField
            id="branchName"
            label="支店名"
            control={branchControl}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <KanseiTextField
            id="address"
            label="住所"
            control={branchControl}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <KanseiTextField
            id="phoneNumber"
            label="電話番号"
            control={branchControl}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <KanseiTextField
            id="faxNumber"
            label="FAX番号"
            control={branchControl}
            required
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={12}>
          <KanseiMultiSelectBox
            control={branchControl}
            name="plugins"
            options={PLUGIN_OPTIONS}
          />
        </Grid>
        <Grid item xs={12}>
          <KanseiButton buttonType="submit" variant="contained">
            更新
          </KanseiButton>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: '10px' }} />
      <Typography sx={{ margin: '10px 0' }}>アカウント情報</Typography>
      <KanseiButton onClick={showCreateModal}>新規作成</KanseiButton>
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>編集</TableCell>
              <TableCell>名前</TableCell>
              <TableCell>ログインID</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyBranch?.users &&
              companyBranch?.users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => showEditModal(user)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.loginId}</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <KanseiDialog open={open} handleClose={handleCloseDialog} maxWidth="lg">
        <Box
          component="form"
          onSubmit={userHandleSubmit(onSubmit)}
          sx={{ width: '300px' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <KanseiTextField
                id="name"
                label="アカウント名"
                control={userControl}
                errors={errors}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <KanseiTextField
                id="loginId"
                label="ログインID"
                control={userControl}
                errors={errors}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <KanseiTextField
                id="email"
                label="email"
                control={userControl}
                errors={errors}
                required
              />
            </Grid>
          </Grid>
          <br />
          <KanseiButton
            style={{ marginRight: '10px' }}
            onClick={handleCloseDialog}
          >
            キャンセル
          </KanseiButton>
          <KanseiButton buttonType="submit">作成</KanseiButton>
        </Box>
      </KanseiDialog>
    </Box>
  )
}
