const NotFoundPage = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>お探しのページは見つかりませんでした。</p>
    </div>
  )
}

export default NotFoundPage
