import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'

import KanseiTextField from 'components/textField'
import { authApi } from 'hooks/api/auth'

interface Form {
  email: string
  pass: string
}

export default function LoginPage() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>()
  const submit = (form: Form) => {
    authApi()
      .signIn(form.email, form.pass)
      .then(() => {
        // TODO navigdateだとsessionが埋まる前に遷移して再度loginするため
        location.href = '/company'
      })
      .catch((res) => {
        alert(res.data.msg)
      })
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          ログイン
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(submit)}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <KanseiTextField
                id="email"
                label="Email"
                control={control}
                errors={errors}
              ></KanseiTextField>
              {errors.email && <p role="alert">idを入力してください</p>}
            </Grid>
            <Grid item xs={12}>
              <KanseiTextField
                id="pass"
                label="パスワード"
                type="password"
                control={control}
                errors={errors}
              ></KanseiTextField>
              {errors.pass && <p role="alert">passwordを入力してください</p>}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            ログイン
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
