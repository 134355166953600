import { Edit } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import KanseiButton from 'components/button'
import { Company } from 'types/company'

interface Props {
  company?: Company
}

export default function CompanyBranches(props: Props) {
  const { company } = props
  const navigate = useNavigate()

  const toDetail = (id: string) => {
    navigate(`/company/${company?.id}/branch/${id}`)
  }

  const toCreate = () => {
    navigate(`/company/${company?.id}/branch/create`)
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Typography>支店一覧</Typography>
      <KanseiButton onClick={toCreate}>新規作成</KanseiButton>
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>編集</TableCell>
              <TableCell>支店名</TableCell>
              <TableCell>住所</TableCell>
              <TableCell>電話番号</TableCell>
              <TableCell>FAX</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {company?.companyBranches &&
              company.companyBranches.map((branch) => (
                <TableRow key={branch.id}>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => toDetail(branch.id!)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell>{branch.branchName}</TableCell>
                  <TableCell>{branch.address}</TableCell>
                  <TableCell>{branch.phoneNumber}</TableCell>
                  <TableCell>{branch.faxNumber}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
