import { Breakpoint, Dialog, DialogContent } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  open: boolean
  maxWidth?: Breakpoint
  handleClose?: () => void
  className?: string
}

export default function KanseiDialog(props: Props) {
  const { children, open, maxWidth = 'md', handleClose, className } = props

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      className={className}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
