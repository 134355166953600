import { Box, Button, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import KanseiBreadcrumbs from 'components/breadcrumb'
import KanseiTextField from 'components/textField'
import { companyApi } from 'hooks/api/company'
import { Company } from 'types/company'
import { CompanyBranch } from 'types/companyBranch'

interface Form {
  id: string
  companyId: string
  branchName: string
  address: string
  phoneNumber: string
  faxNumber: string
}

export default function BranchCreatePage() {
  const [company, setCompany] = useState<Company>()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>()

  useEffect(() => {
    companyApi()
      .findById(id!)
      .then((res) => {
        setCompany(res.data)
      })
  }, [])

  const onSubmit = (form: Form) => {
    const data: CompanyBranch = {
      ...form,
      companyId: id!,
    } as CompanyBranch
    if (confirm('作成しますか？')) {
      companyApi()
        .addBranch(data)
        .then(() => {
          navigate(`/company/${id}`)
        })
    }
  }

  const links = [
    {
      title: '警備会社一覧',
      to: '/company',
    },
    {
      title: company ? company.name : '',
      to: `/company/${id}`,
    },
    {
      title: '支店新規作成',
    },
  ]

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <KanseiBreadcrumbs links={links} style={{ marginBottom: '15px' }} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <KanseiTextField
            id="branchName"
            label="支店名"
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <KanseiTextField
            id="address"
            label="住所"
            control={control}
            errors={errors}
            required
          />
        </Grid>

        <Grid item xs={4}>
          <KanseiTextField
            id="phoneNumber"
            label="電話番号"
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <KanseiTextField
            id="faxNumber"
            label="FAX番号"
            control={control}
            errors={errors}
          />
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        作成
      </Button>
    </Box>
  )
}
