import { Edit } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import KanseiBreadcrumbs from 'components/breadcrumb'
import KanseiButton from 'components/button'
import { companyApi } from 'hooks/api/company'
import { Company } from 'types/company'

export default function CompanyPage() {
  const [companies, setCompanies] = useState<Company[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    companyApi()
      .getAll()
      .then((res) => {
        setCompanies(res.data)
      })
  }, [])

  const toDetail = (id: string) => {
    navigate(`/company/${id}`)
  }

  const toCreate = () => {
    navigate(`/company/create`)
  }

  const links = [
    {
      title: '警備会社一覧',
    },
  ]

  return (
    <Box>
      <KanseiBreadcrumbs links={links} style={{ marginBottom: '15px' }} />
      <KanseiButton onClick={toCreate}>新規作成</KanseiButton>
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>編集</TableCell>
              <TableCell>名前</TableCell>
              <TableCell>住所</TableCell>
              <TableCell>法人番号</TableCell>
              <TableCell>電話番号</TableCell>
              <TableCell>FAX</TableCell>
              <TableCell>インボイス登録番号</TableCell>
              <TableCell>代表者名</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company.id}>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => toDetail(company.id)}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.address}</TableCell>
                <TableCell>{company.corporateNumber}</TableCell>
                <TableCell>{company.phoneNumber}</TableCell>
                <TableCell>{company.faxNumber}</TableCell>
                <TableCell>{company.invoiceRegistrationNumber}</TableCell>
                <TableCell>{company.representativeName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
