import CreatePage from 'pages/company/create/page'
import BranchCreatePage from 'pages/company/detail/branch/create/page'
import CompanyBranchPage from 'pages/company/detail/branch/page'
import DetailPage from 'pages/company/detail/page'
import CompanyPage from 'pages/company/page'

export const companyRoutes = [
  { path: '/company', element: <CompanyPage /> },
  { path: '/company/create', element: <CreatePage /> },
  { path: '/company/:id', element: <DetailPage /> },
  { path: '/company/:id/branch/:branchId', element: <CompanyBranchPage /> },
  { path: '/company/:id/branch/create', element: <BranchCreatePage /> },
]
