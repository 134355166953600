import { Box, Chip, MenuItem, Select } from '@mui/material'
import { CSSProperties } from 'react'
import { Control, Controller } from 'react-hook-form'

import { Option } from 'types/option'

// TODO
interface Props {
  name: string
  options: Option[]
  control: Control<any>
  disabled?: boolean
  minWidth?: number
}

export default function KanseiMultiSelectBox(props: Props) {
  const { options, name, control, disabled = false, minWidth = 200 } = props

  const style: CSSProperties = {
    minWidth: minWidth,
    display: 'inline-block',
  }

  const value = control?._defaultValues[name]
    ? control._defaultValues[name]
    : []
  const toLabel = (value: string | number) => {
    const v = options.find((x) => x.value === value)
    return v?.label
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <Select
          {...field}
          sx={style}
          disabled={disabled}
          multiple
          value={field.value}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: string | number) => (
                <Chip key={value} label={toLabel(value)} />
              ))}
            </Box>
          )}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      )}
    />
  )
}
