import styled from '@emotion/styled'
import { TextField } from '@mui/material'
import { CSSProperties } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

interface Props {
  id: string
  name?: string
  control: Control<any>
  label?: string
  required?: boolean
  disabled?: boolean
  type?: 'text' | 'number' | 'datetime-local' | 'password'
  fullWidth?: boolean
  valiant?: 'standard' | 'outlined' | 'filled'
  errors?: FieldErrors
  size?: 'small' | 'medium'
  style?: CSSProperties
  onInput?: (v: string) => void
}

const StyledTextField = styled(TextField)(() => ({
  '& .Mui-disabled': {
    WebkitTextFillColor: 'black',
  },
  '& .MuiInputBase-input': {
    WebkitTextFillColor: 'black',
  },
}))

export default function KanseiTextField(props: Props) {
  const {
    id,
    name,
    label = '',
    required = false,
    fullWidth = true,
    valiant = 'outlined',
    disabled = false,
    type = 'text',
    control,
    errors = {},
    size = 'small',
    style: style = {},
    onInput,
  } = props

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onInput) {
      onInput(event.target.value)
    }
  }

  const errorMessage = errors[id]?.message

  return (
    <Controller
      name={name || id}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <StyledTextField
          {...field}
          label={label}
          required={required}
          disabled={disabled}
          type={type}
          fullWidth={fullWidth}
          variant={valiant}
          error={!!errorMessage}
          helperText={errorMessage?.toString()}
          size={size}
          sx={style}
          onInput={handleInput}
          autoComplete="off"
        />
      )}
    />
  )
}
