import { AxiosResponse } from 'axios'

import { _axios } from './axios'

const LOGIN_STATUS = '/workflow/login_status'

export const workFlowApi = () => {
  const loginStatus = async (): Promise<AxiosResponse<string>> => {
    return await _axios()
      .get(LOGIN_STATUS)
      .then((res) => {
        res.data = res.data.rows
        return res
      })
  }

  return {
    loginStatus,
  }
}
