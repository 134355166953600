import { createTheme } from '@mui/material'

export const defaultTheme = createTheme({
  typography: {
    fontSize: 12,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '6px 8px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '6px 8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 8px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0 0 10px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [`& .MuiOutlinedInput-root`]: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            paddingLeft: '0 !important',
          },
        },
        input: {
          minHeight: '30px',
        },
      },
    },
  },
})
