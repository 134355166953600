import { Box, Button, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import KanseiBreadcrumbs from 'components/breadcrumb'
import KanseiTextField from 'components/textField'
import { companyApi } from 'hooks/api/company'
import { Company } from 'types/company'

import CompanyBranches from './branch'

interface Form {
  address: string
  corporateNumber: string
  faxNumber: string
  invoiceRegistrationNumber: string
  name: string
  phoneNumber: string
  postalCode: string
  representativeName: string
}

export default function DetailPage() {
  const [company, setCompany] = useState<Company>()
  const { id } = useParams<{ id: string }>()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Form>()

  const fetchData = () => {
    companyApi()
      .findById(id!)
      .then((res) => {
        reset(res.data)
        setCompany(res.data)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onSubmit = (form: Form) => {
    const data = {
      ...form,
    } as Company
    companyApi()
      .edit(id!, data)
      .then(() => {
        fetchData()
      })
  }

  const links = [
    {
      title: '警備会社一覧',
      to: '/company',
    },
    {
      title: company ? company.name : '',
    },
  ]

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ '& .MuiTextField-root': { m: 1 }, maxWidth: '500px' }}
    >
      <KanseiBreadcrumbs links={links} style={{ marginBottom: '15px' }} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <KanseiTextField
            id="name"
            label="Name"
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <KanseiTextField
            id="corporateNumber"
            label="法人番号"
            control={control}
            errors={errors}
            required
          />
        </Grid>

        <Grid item xs={4}>
          <KanseiTextField
            id="phoneNumber"
            label="電話番号"
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <KanseiTextField
            id="faxNumber"
            label="FAX番号"
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <KanseiTextField
            id="invoiceRegistrationNumber"
            label="インボイス登録番号"
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={4}>
          <KanseiTextField
            id="address"
            label="住所"
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <KanseiTextField
            id="postalCode"
            label="郵便番号"
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <KanseiTextField
            id="representativeName"
            label="代表者名"
            control={control}
            errors={errors}
            required
          />
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        更新
      </Button>
      <CompanyBranches company={company} />
    </Box>
  )
}
